import { createRouter,createWebHashHistory } from "vue-router";
import store from '@/store'
import util from '@/util/util'

// 主后台管理
// 城市界面
import admin from '@/pages/admin/home.vue'

// 路由规则
const routes = [
	{
		path: '/',
		redirect: '/login',
        meta: {
            title: '青穗网登录'
        }
	},
    {
        name: '后台登录',
        path:'/login',
        component: () => import('@/pages/login/index.vue'),
        meta: {
            title: '青穗网登录'
        }
    },
    // 主后台
    {

        meta: {
            title: '青穗网-主后台-管理端'
        },
        path:'/admin',
        component:admin,
        children: [
            {
                // '后台首页',
                path: 'index/index',
                component: () => import('@/pages/admin/index/index.vue')
            },

            // 文件
            {
                // '文件',
                path: 'file/file',
                component: () => import('@/pages/admin/file/file.vue')
            },

            // 系统管理管理
            
            // 导航管理
                {
                    // '主后台',
                    path: 'config/nav/nav',
                    component: () => import('@/pages/admin/config/nav/nav.vue')
                },
                {
                    // '代理',
                    path: 'config/nav/dai',
                    component: () => import('@/pages/admin/config/nav/dai.vue')
                },
                {
                    // '城市',
                    path: 'config/nav/cheng',
                    component: () => import('@/pages/admin/config/nav/cheng.vue')
                },
                {
                    // '仓库',
                    path: 'config/nav/cang',
                    component: () => import('@/pages/admin/config/nav/cang.vue')
                },
                {
                    // '供应商',
                    path: 'config/nav/gong',
                    component: () => import('@/pages/admin/config/nav/gong.vue')
                },
            // 导航管理

            // 管理员
                {
                    // '管理员',
                    path: 'user/user',
                    component: () => import('@/pages/admin/user/user.vue')
                },
                {
                    // '日志',
                    path: 'user/log',
                    component: () => import('@/pages/admin/user/log.vue')
                },
                {
                    // '权限',
                    path: 'user/rank',
                    component: () => import('@/pages/admin/user/rank.vue')
                },
                {
                    // '权限',
                    path: 'user/rankPhone',
                    component: () => import('@/pages/admin/user/rankPhone.vue')
                },
            // 管理员

            // 代理管理
                {
                    // '代理列表',
                    path: 'agency/agency',
                    component: () => import('@/pages/admin/agency/agency.vue')
                },
                {
                    // '管理员',
                    path: 'agency/user',
                    component: () => import('@/pages/admin/agency/user.vue')
                },
            // 代理管理

            // 供应商管理
                {
                    // '供应商列表',
                    path: 'supply/supply',
                    component: () => import('@/pages/admin/supply/supply.vue')
                },
                {
                    // '管理员',
                    path: 'supply/user',
                    component: () => import('@/pages/admin/supply/user.vue')
                },
            // 供应商管理
            
        ],
    },

    // 代理
    {

        meta: {
            title: '青穗网-代理-管理端'
        },
        path:'/agency',
        component: () => import('@/pages/agency/home.vue'),
        children: [
            {
                // '后台首页',
                path: 'index/index',
                component: () => import('@/pages/agency/index/index.vue')
            },

            // 文件
            {
                // '文件',
                path: 'file/file',
                component: () => import('@/pages/agency/file/file.vue')
            },

            // 系统管理管理

            // 管理员
                {
                    // '管理员',
                    path: 'user/user',
                    component: () => import('@/pages/agency/user/user.vue')
                },
                {
                    // '日志',
                    path: 'user/log',
                    component: () => import('@/pages/agency/user/log.vue')
                },
                {
                    // '权限',
                    path: 'user/rank',
                    component: () => import('@/pages/agency/user/rank.vue')
                },
                {
                    // '权限',
                    path: 'user/rankPhone',
                    component: () => import('@/pages/agency/user/rankPhone.vue')
                },
            // 管理员

            // 城市
                {
                    // '城市',
                    path: 'area/area',
                    component: () => import('@/pages/agency/area/area.vue')
                },
                {
                    // '日志',
                    path: 'area/user',
                    component: () => import('@/pages/agency/area/user.vue')
                },
            // 城市

            // 城市
            {
                // '城市',
                path: 'granary/granary',
                component: () => import('@/pages/agency/granary/granary.vue')
            },
            {
                // '日志',
                path: 'granary/user',
                component: () => import('@/pages/agency/granary/user.vue')
            },
        // 城市
            
        ],
    },

    // 城市
    {

        meta: {
            title: '青穗网-城市-管理端'
        },
        path:'/area',
        component: () => import('@/pages/area/home.vue'),
        children: [
            {
                path: 'index/index',
                component: () => import('@/pages/area/index/index.vue')
            },

            // 文件
            {
                // '文件',
                path: 'file/file',
                component: () => import('@/pages/area/file/file.vue')
            },

            // 系统管理管理

            // 管理员
                {
                    // '管理员',
                    path: 'user/user',
                    component: () => import('@/pages/area/user/user.vue')
                },
                {
                    // '日志',
                    path: 'user/log',
                    component: () => import('@/pages/area/user/log.vue')
                },
                {
                    // '权限',
                    path: 'user/rank',
                    component: () => import('@/pages/area/user/rank.vue')
                },
                {
                    // '权限',
                    path: 'user/rankPhone',
                    component: () => import('@/pages/area/user/rankPhone.vue')
                },
                {
                    // '权限',
                    path: 'user/tiList',
                    component: () => import('@/pages/area/user/tiList.vue')
                },
                {
                    // '提成汇总',
                    path: 'user/tiCou',
                    component: () => import('@/pages/area/user/tiCou.vue')
                },
            // 管理员

            // 广告图片
                {
                    // '轮播图',
                    path: 'file/swiper',
                    component: () => import('@/pages/area/file/swiper.vue')
                },
                {
                    // '广告',
                    path: 'file/advertising',
                    component: () => import('@/pages/area/file/advertising.vue')
                },
                {
                    // '协议',
                    path: 'file/agreement',
                    component: () => import('@/pages/area/file/agreement.vue')
                },
                {
                    // '专场',
                    path: 'file/special',
                    component: () => import('@/pages/area/file/special.vue')
                },
            // 广告图片

            // 产品管理
                {
                    // '产品库',
                    path: 'goods/goods',
                    component: () => import('@/pages/area/goods/goods.vue')
                },
                {
                    // '分类',
                    path: 'goods/types',
                    component: () => import('@/pages/area/goods/types.vue')
                },
                {
                    // '单位',
                    path: 'goods/unit',
                    component: () => import('@/pages/area/goods/unit.vue')
                },
                {
                    // '品牌',
                    path: 'goods/pin',
                    component: () => import('@/pages/area/goods/pin.vue')
                },
            // 产品管理

            // 任务管理
                {
                    // '任务管理',
                    path: 'task/chang/:state/',
                    component: () => import('@/pages/area/task/chang.vue')
                },
                {
                    // '任务管理',
                    path: 'task/chang/1/goods/',
                    component: () => import('@/pages/area/task/goods.vue')
                },
                {
                    // '任务管理',
                    path: 'task/chang/1/ruleSum/',
                    component: () => import('@/pages/area/task/ruleSum.vue')
                },
                {
                    // '任务管理',
                    path: 'task/chang/1/ruleWei/',
                    component: () => import('@/pages/area/task/ruleWei.vue')
                },

                {
                    // '任务管理',
                    path: 'task/chang/2/goods/',
                    component: () => import('@/pages/area/task/goods.vue')
                },
                {
                    // '任务管理',
                    path: 'task/chang/2/ruleSum/',
                    component: () => import('@/pages/area/task/ruleSum.vue')
                },
                {
                    // '任务管理',
                    path: 'task/chang/2/ruleWei/',
                    component: () => import('@/pages/area/task/ruleWei.vue')
                },

                {
                    // '任务管理',
                    path: 'task/chang/3/goods/',
                    component: () => import('@/pages/area/task/goods.vue')
                },
                {
                    // '任务管理',
                    path: 'task/chang/3/ruleSum/',
                    component: () => import('@/pages/area/task/ruleSum.vue')
                },
                {
                    // '任务管理',
                    path: 'task/chang/3/ruleWei/',
                    component: () => import('@/pages/area/task/ruleWei.vue')
                },
                
                // 活动
                {
                    // '任务管理',
                    path: 'task/hui/:state/',
                    component: () => import('@/pages/area/task/hui.vue')
                },
                {
                    // '规格',
                    path: 'task/hui/1/ruleSum/',
                    component: () => import('@/pages/area/task/ruleSum.vue')
                },
                {
                    // '规格',
                    path: 'task/hui/1/ruleWei/',
                    component: () => import('@/pages/area/task/ruleWei.vue')
                },
                {
                    // '任务管理',
                    path: 'task/hui/1/times/',
                    component: () => import('@/pages/area/task/times.vue')
                },
                {
                    // '任务管理',
                    path: 'task/hui/1/goods/',
                    component: () => import('@/pages/area/task/goods.vue')
                },

                {
                    // '规格',
                    path: 'task/hui/2/ruleSum/',
                    component: () => import('@/pages/area/task/ruleSum.vue')
                },
                {
                    // '规格',
                    path: 'task/hui/2/ruleWei/',
                    component: () => import('@/pages/area/task/ruleWei.vue')
                },
                {
                    // '任务管理',
                    path: 'task/hui/2/times/',
                    component: () => import('@/pages/area/task/times.vue')
                },
                {
                    // '任务管理',
                    path: 'task/hui/2/goods/',
                    component: () => import('@/pages/area/task/goods.vue')
                },

                {
                    // '规格',
                    path: 'task/hui/3/ruleSum/',
                    component: () => import('@/pages/area/task/ruleSum.vue')
                },
                {
                    // '规格',
                    path: 'task/hui/3/ruleWei/',
                    component: () => import('@/pages/area/task/ruleWei.vue')
                },
                {
                    // '任务管理',
                    path: 'task/hui/3/times/',
                    component: () => import('@/pages/area/task/times.vue')
                },
                {
                    // '任务管理',
                    path: 'task/hui/3/goods/',
                    component: () => import('@/pages/area/task/goods.vue')
                },

                {
                    // '规格',
                    path: 'task/hui/4/ruleSum/',
                    component: () => import('@/pages/area/task/ruleSum.vue')
                },
                {
                    // '规格',
                    path: 'task/hui/4/ruleWei/',
                    component: () => import('@/pages/area/task/ruleWei.vue')
                },
                {
                    // '任务管理',
                    path: 'task/hui/4/times/',
                    component: () => import('@/pages/area/task/times.vue')
                },
                {
                    // '任务管理',
                    path: 'task/hui/4/goods/',
                    component: () => import('@/pages/area/task/goods.vue')
                },
                
            // 任务管理

            // 区域管理
                {
                    // '区域管理',
                    path: 'region/region',
                    component: () => import('@/pages/area/region/region.vue')
                },
            // 区域管理

            // 店铺管理
                {
                    // '店铺管理',
                    path: 'shop/apply/:state/',
                    component: () => import('@/pages/area/shop/apply.vue')
                },

                {
                    // '店铺管理',
                    path: 'shop/shop/:state/',
                    component: () => import('@/pages/area/shop/shop.vue')
                },

                {
                    // '店铺管理',
                    path: 'shop/admin/:state/:shopId/:shopName/',
                    component: () => import('@/pages/area/shop/admin.vue')
                },

                {
                    // '店铺管理',
                    path: 'shop/code/:state/',
                    component: () => import('@/pages/area/shop/code.vue')
                },

                {
                    // '账户申请',
                    path: 'shop/sum/:state/',
                    component: () => import('@/pages/area/shop/sum.vue')
                },

                {
                    // '店铺设置',
                    path: 'shop/default/',
                    component: () => import('@/pages/area/shop/default.vue')
                },
                {
                    // '店铺设置',
                    path: 'shop/newf',
                    component: () => import('@/pages/area/shop/newf.vue')
                },
            // 店铺管理

            // 优惠任务管理
                {
                    // '任务管理',
                    path: 'coupon/task/:state/',
                    component: () => import('@/pages/area/coupon/task.vue')
                },
                {
                    // '优惠管理',
                    path: 'coupon/coupon',
                    component: () => import('@/pages/area/coupon/coupon.vue')
                },

                {
                    // '店铺充值',
                    path: 'coupon/sum/:state/',
                    component: () => import('@/pages/area/coupon/sum.vue')
                },
                {
                    // '充值订单',
                    path: 'coupon/order',
                    component: () => import('@/pages/area/coupon/order.vue')
                },

                {
                    // '积分活动',
                    path: 'coupon/fee',
                    component: () => import('@/pages/area/coupon/fee.vue')
                },
                
            // 优惠任务管理

            // 报价
                // 供货价
                {
                    // '任务',
                    path: 'offer/supply/task/:state/',
                    component: () => import('@/pages/area/offer/supply/task.vue')
                },
                {
                    // '规格',
                    path: 'offer/supply/1/rule',
                    component: () => import('@/pages/area/offer/supply/rule.vue')
                },
                {
                    // '规格',
                    path: 'offer/supply/2/rule',
                    component: () => import('@/pages/area/offer/supply/rule.vue')
                },
                {
                    // '规格',
                    path: 'offer/supply/3/rule',
                    component: () => import('@/pages/area/offer/supply/rule.vue')
                },
                {
                    // '规格',
                    path: 'offer/supply/4/rule',
                    component: () => import('@/pages/area/offer/supply/rule.vue')
                },

                // 售卖价
                {
                    // '任务',
                    path: 'offer/shou/supply/',
                    component: () => import('@/pages/area/offer/shou/supply.vue')
                },
                {
                    // '任务',
                    path: 'offer/shou/supply/rule/',
                    component: () => import('@/pages/area/offer/shou/supplyrule.vue')
                },
                {
                    // '任务',
                    path: 'offer/shou/task/:state/',
                    component: () => import('@/pages/area/offer/shou/task.vue')
                },
                {
                    // '规格',
                    path: 'offer/shou/1/rule',
                    component: () => import('@/pages/area/offer/shou/rule.vue')
                },
                {
                    // '规格',
                    path: 'offer/shou/2/rule',
                    component: () => import('@/pages/area/offer/shou/rule.vue')
                },
                {
                    // '规格',
                    path: 'offer/shou/3/rule',
                    component: () => import('@/pages/area/offer/shou/rule.vue')
                },
                {
                    // '规格',
                    path: 'offer/shou/4/rule',
                    component: () => import('@/pages/area/offer/shou/rule.vue')
                },


                // 单品调价
                {
                    // '任务',
                    path: 'offer/area/task/:state/',
                    component: () => import('@/pages/area/offer/area/task.vue')
                },
                {
                    // '规格',
                    path: 'offer/area/1/rule',
                    component: () => import('@/pages/area/offer/area/rule.vue')
                },
                {
                    // '规格',
                    path: 'offer/area/2/rule',
                    component: () => import('@/pages/area/offer/area/rule.vue')
                },
                {
                    // '规格',
                    path: 'offer/area/3/rule',
                    component: () => import('@/pages/area/offer/area/rule.vue')
                },
                {
                    // '规格',
                    path: 'offer/area/4/rule',
                    component: () => import('@/pages/area/offer/area/rule.vue')
                },
            // 报价

            // 订单
                {
                    path: 'order/one/:state/',
                    component: () => import('@/pages/area/order/one.vue')
                },
            // 订单

            // 供应商
                {
                    path: 'supply/supply',
                    component: () => import('@/pages/area/supply/supply.vue')
                },
                {
                    path: 'supply/user/:infoId/:infoName/',
                    component: () => import('@/pages/area/supply/user.vue')
                },
            // 供应商

            // 图表
                {
                    path: 'echarts/index/',
                    component: () => import('@/pages/area/echarts/index.vue')
                },
            // 图表

        ],
    },

    // 仓库
    {

        meta: {
            title: '青穗网-仓库-管理端'
        },
        path:'/granary',
        component: () => import('@/pages/granary/home.vue'),
        children: [
            {
                // '后台首页',
                path: 'index/index',
                component: () => import('@/pages/granary/index/index.vue')
            },

            // 文件
            {
                // '文件',
                path: 'file/file',
                component: () => import('@/pages/granary/file/file.vue')
            },

            // 系统管理管理

            // 管理员
                {
                    // '管理员',
                    path: 'user/user',
                    component: () => import('@/pages/granary/user/user.vue')
                },
                {
                    // '日志',
                    path: 'user/log',
                    component: () => import('@/pages/granary/user/log.vue')
                },
                {
                    // '权限',
                    path: 'user/rank',
                    component: () => import('@/pages/granary/user/rank.vue')
                },
                {
                    // '权限',
                    path: 'user/rankPhone',
                    component: () => import('@/pages/granary/user/rankPhone.vue')
                },
            // 管理员

            // 店铺相关
            {
                // '配货清单',
                path: 'shop/code/:state/',
                component: () => import('@/pages/granary/shop/code.vue')
            },
            // 店铺相关

            
        ],
    },

    // 供应商
    {

        meta: {
            title: '青穗网-供应商-管理端'
        },
        path:'/supply',
        component: () => import('@/pages/supply/home.vue'),
        children: [
            {
                // '后台首页',
                path: 'index/index',
                component: () => import('@/pages/supply/index/index.vue')
            },

            // 文件
            {
                // '文件',
                path: 'file/file',
                component: () => import('@/pages/supply/file/file.vue')
            },

            // 系统管理管理

            // 管理员
                {
                    // '管理员',
                    path: 'user/user',
                    component: () => import('@/pages/supply/user/user.vue')
                },
                {
                    // '日志',
                    path: 'user/log',
                    component: () => import('@/pages/supply/user/log.vue')
                },
                {
                    // '权限',
                    path: 'user/rank',
                    component: () => import('@/pages/supply/user/rank.vue')
                },
                {
                    // '权限',
                    path: 'user/rankPhone',
                    component: () => import('@/pages/supply/user/rankPhone.vue')
                },
            // 管理员

            // 加工
                {
                    // '加工',
                    path: 'order/order/:state',
                    component: () => import('@/pages/supply/order/order.vue')
                },
            // 加工
            
            // 发货订单
                {
                    // '发货订单',
                    path: 'order/fa/:state',
                    component: () => import('@/pages/supply/order/fa.vue')
                },
            // 发货订单

            // 结算订单
                {
                    // '结算订单',
                    path: 'order/jie/:state',
                    component: () => import('@/pages/supply/order/jie.vue')
                },
            // 结算订单

            // 报价
                {
                    // '任务',
                    path: 'offer/task/:state/',
                    component: () => import('@/pages/supply/offer/task.vue')
                },
                {
                    // '规格',
                    path: 'offer/1/rule',
                    component: () => import('@/pages/supply/offer/rule.vue')
                },
                {
                    // '规格',
                    path: 'offer/2/rule',
                    component: () => import('@/pages/supply/offer/rule.vue')
                },
                {
                    // '规格',
                    path: 'offer/3/rule',
                    component: () => import('@/pages/supply/offer/rule.vue')
                },
                {
                    // '规格',
                    path: 'offer/4/rule',
                    component: () => import('@/pages/supply/offer/rule.vue')
                },

            // 报价

        ],
    },
]

const router = createRouter({
    history:createWebHashHistory(),
    routes
})

var setLogin = function(fun = ''){
    store.state.user = 0
    util.localSet('user',0);
    store.state.token = 0
    util.localSet('token',0);
    store.state.port = 0
    util.localSet('port',0);
    store.state.rank = 0
    util.localSet('rank',0);
    store.state.nav = 0
    util.localSet('nav',0);
    fun ? fun() : '' ;
}

// 端口 1总后台2代理3城市4仓库5供应商

router.beforeEach(async (to, from, next) => {

    if (to.meta.title) {
        document.title = to.meta.title
    }

    var path = to.path.split('/');
    var port_name = '';
    if(path.length >= 2){
        port_name = path[1];
    }

    if(to.path == '/login'){
        util.loginIs(function(isLogin){

            if(isLogin){

                next(util.loginUrl(true));
                
            }else{

                next();

            }
        },false)
    }else{
        util.loginIs(function(isLogin){

            if(isLogin){

                var paramsCou = 0;
                if(to.params){

                    for(var k in to.params){
                        if(k != 'state'){
                            paramsCou ++;
                        }
                    }

                }

                path.splice(path.length - paramsCou, paramsCou);

                var urlKey = path.join('_');
                // console.log('----------URL处理----------')
                // console.log('URL处理1',paramsCou)
                // console.log('URL处理2',urlKey)
                // console.log('URL处理3',port_name)
                // console.log('URL处理4',store.state.rank)
                // console.log('URL处理5',to.params)
                // console.log('URL处理6',to)
                // console.log('----------URL处理----------')
                if (typeof store.state.rank[port_name][urlKey] !== 'undefined') {
                    store.state.rankKey = urlKey
                    store.state.rankBut = store.state.rank[port_name][urlKey]
                    next();
                }else{
                    if(!store.state.rank[port_name]){
                        setLogin(function(){
                            next('/login');
                        })
                    }else{
                        alert('您没有权限！！！');
                        next(false);
                    }
                }
                
            }else{

                alert('您还没有登录, 请先登录');
                store.state.user = 0
                store.state.token = 0
                next('/login');

            }
        })
        
    }
})

export default router